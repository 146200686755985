@font-face {
  font-family: 'Operator Mono';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("/public/fonts/OperatorMono-Book.woff2") format("woff2");
}

@font-face {
  font-family: 'Operator Mono';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("/public/fonts/OperatorMono-BookItalic.woff2") format("woff2");
}

@font-face {
  font-family: 'Operator Mono';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("/public/fonts/OperatorMono-Bold.woff2") format("woff2");
}

@font-face {
  font-family: 'Operator Mono';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("/public/fonts/OperatorMono-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: 'Operator Mono';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("/public/fonts/OperatorMono-Light.woff2") format("woff2");
}

@font-face {
  font-family: 'Operator Mono';
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("/public/fonts/OperatorMono-LightItalic.woff2") format("woff2");
}
