@media screen and (max-width: 992px) {
  section#skills,
  section#works,
  section#blog,
  section#about,
  section#home,
  header .container {
    width: calc(100vw - 30px);
    max-width: calc(100vw - 30px);
    margin: 0 15px;
  }

  header {
    nav#main {
      font-size: 16px;
    }

    [href] {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  body .swiper-container {
    width: 100%;
    max-width: 100%;
  }

  section#skills .skills-container {
    grid-template-columns: 1fr 1fr;
  }

  section#skills .skills-container > .skill > strong {
    font-size: 62px;
  }

  .skill-empty {
    display: none;
  }

  section#about #about-img,
  section#about .text {
    max-width: 100%;
    flex: 0 0 100%;
    height: auto;
    margin: 0;
  }

  section#home h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  section#works h3 {
    font-size: 24px;
  }

  p {
    position: relative;
    z-index: 50;
  }

  #blog-modal-container {
    left: 10px;
    right: 10px;
    width: calc(100% - 20px);
  }

  #blog-modal-content {
    padding: 0 10px;
    margin: 0;
    height: calc(100% - 20vh - 15px);
  }
}
