$body-color: #949ba0;
$body-bg: #273139;

$primary: #ff5127;
$gray: #323c43;

@import "fonts";
@import "utils";
@import "header";
@import "footer";
@import "pages";
@import "blog";
@import "swiper";
@import "mediaqueries";

#alerts {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  opacity: 0;
  transition: opacity ease-in-out 0.75s;
  //filter: blur(15px);

  .gravity-alert & {
    opacity: 1;
  }

  .alert {
    position: fixed;
    $alertSize: 75vh;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: $alertSize;
      height: $alertSize;
      background-image: radial-gradient(rgba($primary, 0.5) 0%, transparent 75%);
      background-position: -#{$alertSize/2} -#{$alertSize/2};
      background-repeat: no-repeat;
      transform-origin: left top;

      .gravity-alert & {
        animation: rotate linear 2s infinite;
      }
    }

    &::after {
      content: "";
      position: absolute;
      right: 100%;
      bottom: 100%;
      display: block;
      width: $alertSize;
      height: $alertSize;
      background-image: radial-gradient(rgba($primary, 0.5) 0%, transparent 75%);
      background-position: #{$alertSize/2} #{$alertSize/2};
      background-repeat: no-repeat;
      transform-origin: right bottom;

      .gravity-alert & {
        animation: rotate linear 2s infinite;
      }
    }

    &.one {
      left: 0;
      top: 0;
    }

    &.two {
      right: 0;
      top: 0;
    }

    &.three {
      right: 0;
      bottom: 0;
    }

    &.four {
      left: 0;
      bottom: 0;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
