
section#home {
  display: flex;
  flex-wrap: wrap;
  padding-top: calc(50vh - 100px);
  min-height: 100vh;
  text-align: center;

  > .head {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
    position: relative;
    z-index: 60;
    pointer-events: none;
  }

  h1 {
    color: white;
    font-size: 62px;
    margin-bottom: 0;

    span {
      color: $primary;
    }
  }
}

section#works {
  position: relative;
  padding: 5vh 0;
  overflow-x: hidden;
  text-align: center;

  h3,
  p {
    transition: color ease-in-out 0.3s;
  }

  .swiper-slide {
    p {
      font-size: 16px;
    }
  }

  picture {
    & > img {
      position: absolute;
      will-change: transform;
      animation: scrollingImage linear 30s infinite paused;
    }

    &:hover {
      > img {
        animation-play-state: running;
      }
    }
  }
}

@keyframes scrollingImage {
  0%,
  10%,
  100% {
    transform: translateY(0%);
  }

  66% {
    transform: translateY(calc(-100% + 640px));
  }
}

section#blog {
  position: relative;
  padding: 5vh 0;
  overflow-x: hidden;
  text-align: center;
}

section#about {
  width: 1150px;
  max-width: calc(100vw - 200px);
  margin: 0 auto;
  padding: 12.5vh 0 15vh;

  .block {
    position: relative;
    z-index: 60;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  #about-img {
    display: block;
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .text {
    flex: 0 0 calc(40% - 20px);
    max-width: calc(40% - 20px);
    margin-left: 10%;
  }
}

section#skills {
  width: 1150px;
  max-width: calc(100vw - 200px);
  padding: 10vh 0;
  margin: 0 auto;
  text-align: center;

  > * {
    position: relative;
  }

  .skills-container {
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    justify-items: stretch;
    align-items: start;

    > .skill {
      position: relative;
      z-index: 60;
      display: flex;
      padding: 10px;
      background: $gray;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      transition: background-color ease 0.3s;

      > * {
        flex: 0 0 100%;
        transition: color ease 0.3s;
      }

      > strong {
        order: 2;
        font-weight: 500;
        font-size: 92px;
        font-style: italic;
        color: white;
        margin: 1rem 0;
      }

      > em {
        font-style: normal;
      }

      > h3 {
        order: 3;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }

      &:hover {
        background: white;
        color: $body-bg;

        > strong {
          color: $body-bg;
        }
      }
    }
  }
}
