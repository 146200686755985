
body .swiper-container {
  position: relative;
  z-index: 75;
  width: 1150px;
  max-width: calc(100vw - 200px);
  overflow: visible;
  padding: 7vh 0;
  text-align: left;
}

.swiper-scrollbar {
  position: absolute;
  left: 12.5%;
  bottom: 0;
  display: block;
  width: 75%;
  height: 15px;
  background-color: $gray;
  border-radius: 15px;
}

.swiper-scrollbar-drag {
  height: 15px;
  background: $primary;
  border-radius: 15px;
}

body .swiper-slide {
  will-change: transform, opacity;
  filter: grayscale(1);
  opacity: 0.25;
  transition: all ease-in-out 0.3s;

  picture {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 60vh;
    max-height: 640px;
  }
}

body [data-slide-per-view="2"] .swiper-slide-active + .swiper-slide,
body .swiper-slide-active {
  opacity: 1;
  filter: grayscale(0);

  h3 {
    transition-delay: 0.25s;
    color: white;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 5;
  display: block;
  height: 100%;
  width: auto;
  cursor: pointer;
}

.swiper-button-next {
  right: -100%;
  left: 100%;
}

.swiper-button-prev {
  left: -100%;
  right: 100%;
}