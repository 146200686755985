
html {
  font-family: 'Operator Mono', sans-serif;
}

body {
  color: $body-color;
  margin: 0;
  padding: 0;

  &.body-hovered {
    cursor: grab;
  }
}

::-moz-selection { /* Code for Firefox */
  background: rgba($gray, 0.4);
}

::selection {
  background: rgba($gray, 0.4);
}

* {
  box-sizing: border-box;
}

a {
  color: white;
  text-decoration: none;
  transition: color ease 0.2s, background ease 0.2s;

  &:hover {
    color: white;
  }
}

h2 {
  position: relative;
  z-index: 60;
  color: white;
  font-size: 62px;
  margin-bottom: 0;
  margin-top: 0;
}

h3 {
  font-size: 36px;
  font-weight: 400;
  font-style: italic;
  margin: 1rem 0 0;
}

p {
  color: $body-color;
  font-size: 18px;
  font-style: italic;
  line-height: 1.6;
}

#canvas > canvas {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  pointer-events: none;
}
