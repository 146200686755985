
header {
  position: fixed;
  z-index: 145;
  top: 30px;
  left: 0;
  right: 0;

  .container {
    width: 1150px;
    max-width: calc(100vw - 200px);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
  }

  nav#main {
    position: relative;
    height: 54px;
    background: rgba($gray, 0.75);
    backdrop-filter: blur(15px) brightness(1.4);
    border-radius: 27px;
    font-size: 16px;
  }

  nav#tools {
    margin-left: 20px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 54px;
      width: 54px;
      padding: 5px;
      border: 0;
      background: rgba($gray, 0.75);
      backdrop-filter: blur(10px);
      border-radius: 54px;
      font-size: 28px;
      font-weight: 700;
      color: white;
      cursor: pointer;
      transition: color ease-in-out 0.2s, background-color ease-in-out 0.2s;

      &.active {
        background-color: $primary;
        color: $body-bg;
      }

      &:hover,
      &:focus,
      &:active {
        outline: none;
      }

      &#gravity.active {
        animation: rotate ease 3s infinite;
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  [href] {
    padding: 10px 20px;
    border-radius: 24px;

    &.active,
    &:hover {
      background: $primary;
    }
  }

  #active-element {
    display: block;
    position: absolute;
    top: 7px;
    height: 39px;
    width: 40px;
    background: darken($primary, 12.5%);
    border-radius: 24px;
    transition: left ease-in-out 0.4s, width ease-in-out 0.4s, opacity ease-in-out 0.2s;
  }

  ul {
    display: flex;
    position: relative;
    z-index: 5;
    list-style: none;
    margin: 0;
    padding: 7px;

    li {
      display: inline-block;
      margin: 10px 0;
    }
  }
}
